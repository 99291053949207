require("../scss/styles.scss");
require("swiper/css/swiper.min.css");
require("odometer/themes/odometer-theme-minimal.css");

$(document).ready(function () {

  let odometer = new OdometerBlock({ el: $('.odometer-block')[0], theme: 'minimal', format: '( ddd)' });

  $(window).on("scroll", function () {
    autoSelectionScroll();
  });

  function  autoSelectionScroll() {
    let blockTop = $(".auto-selection .auto-selection-first");
    let pin = $(".auto-selection .pin-icon");

    if ($(window).scrollTop() + $(window).height()/2 >= blockTop.offset().top
      && $(window).scrollTop() + $(window).height()/2 <= blockTop.offset().top + blockTop.outerHeight()) {

      if (!$(".search-block").hasClass("search-block-dis")) {
        setTimeout(function() {
          $(".search-block").addClass("search-block-dis");
          odometer.update(85430123);
        },1000)
      }

      if (!$(".auto-selection-img").hasClass("auto-selection-img-anim-run")) {
        setTimeout(function() {$(".auto-selection-img").addClass("auto-selection-img-anim-run");},3000)
      }

      if (!$(".auto-selection-circle-1").hasClass("auto-selection-circle-stop")) {
        setTimeout(function() {$(".auto-selection-circle-1").addClass("auto-selection-circle-stop");},4000)
      }

      if (!$(".auto-selection-circle-2").hasClass("auto-selection-circle-stop")) {
        setTimeout(function() {$(".auto-selection-circle-2").addClass("auto-selection-circle-stop");},4000)
      }

      if (!pin.hasClass("pin-icon-run")) {
        pin.addClass("pin-icon-run");
      }


    } else {
      if ($(".auto-selection-img").hasClass("auto-selection-img-anim-run")) {
        $(".auto-selection-img").removeClass("auto-selection-img-anim-run");
      }
      if (pin.hasClass("pin-icon-run")) {
        pin.removeClass("pin-icon-run");
      }
      if ($(".search-block").hasClass("search-block-dis")) {
        $(".search-block").removeClass("search-block-dis");
        odometer.update(0);
      }
      setTimeout(function() {
        if ($(".auto-selection-circle-1").hasClass("auto-selection-circle-stop")) {
          $(".auto-selection-circle-1").removeClass("auto-selection-circle-stop");
        }

        if ($(".auto-selection-circle-2").hasClass("auto-selection-circle-stop")) {
          $(".auto-selection-circle-2").removeClass("auto-selection-circle-stop");
        }
      },1500);
    }
  }

  new Swiper(".swiper-container", {
    direction: "horizontal",
    loop: true,
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    coverflowEffect: {
      rotate: -10,
      stretch: 30,
      depth: 200,
      modifier: 1,
      slideShadows : false,
    },

    navigation: {
      nextEl: ".swiper-nav-next",
      prevEl: ".swiper-nav-prev"
    }
  });

  $(".header-nav").on("click",".header-nav-item", function (event) {
    event.preventDefault();
    let id  = $(this).attr('href'),
      top = $(id).offset().top;
    $('body,html').animate({scrollTop: top}, 1500);
  });

  $(".header-burger").on("click", function (event) {
    if(!$(".header-nav").hasClass("header-nav-active")) {
      $(".header-nav").addClass("header-nav-active")
      $(this).addClass("header-burger-close")
    } else {
      $(".header-nav").removeClass("header-nav-active")
      $(this).removeClass("header-burger-close")
    }
  });
});